﻿.secondary-actions {
    .secondary-actions-ctas {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media (min-width: 640px) {
            flex-direction: row;
            justify-content: flex-start;
        }

        .btn {
            display: flex;
            align-items: center;
            margin: 0;
            width: 75%;

            &:first-of-type {
                margin-bottom: 10px;
            }

            .btn-icon {
                display: inline-block;
                margin-right: 5px;
            }

            @media (min-width: 640px) {
                width: auto;

                &:first-of-type {
                    margin-bottom: 0;
                    margin-right: 15px;
                }
            }
        }
    }

    .secondary-actions-share, .social-media {
        display: flex;
        list-style-type: none;
        margin: 0;
        font-size: 2rem;

        li a {
            display: block;
            margin-right: 15px;
            line-height: 1;
        }
    }
}

.modal-dialog .modal-content {
    .secondary-actions {
        .secondary-actions-ctas {
            justify-content: center;

            .btn {
                @media (min-width: 640px) {
                    width: 50%;
                }
            }
        }

        .social-media {
            display: flex;
            list-style-type: none;
        }
    }
}